import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { BASE_URL } from "./../global";
import { useHistory } from 'react-router-dom';
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";

// import  './App.css';
import { css } from "@emotion/core";

import { DropdownButton, Dropdown, ButtonGroup, Button } from 'react-bootstrap';
const axios = require("axios");

const Signup = (e) => {
    const history = useHistory();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");

    const submit = (e) => {
        let url = BASE_URL;

        const query = `INSERT INTO users(firstNam, lastName, emailId, mobileNo, password, isMember, status) VALUES('${firstName}', '${lastName}', '${email}', '${mobile}','${password}', 1, 1)`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        console.log(query)
        axios
            .post(url, data)
            .then((res) => {
                toast("submitted successfully");
                history.push("/login")
                // setTimeout(() => {
                //     this.refreshParties();
                // }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    }


    return (

        <div className="wrapper background text-center" style={{ marginTop: '1em' }}>

            <div className="Heading">
                <img
                    src={MASTER_LOGO}
                    alt="logo"
                    className="img-fluid"
                    width={300}
                    height={200}
                />
            </div>
            <div
                className="pt-5 pb-5 pl-3 pr-3 w-25 login-temp"
                style={{ boxShadow: "0px 0px 30px 0px rgba(30, 23, 16, 0.2)", margin: 'auto' }}
            >
                <div className="text-center mb-3">

                    <h5 className="sub-head">SIGN UP</h5>
                </div>


                <div className="input-group  mt-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="name"
                        style={{ marginLeft: '1em', marginRight: '1em' }}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </div>

                <div className="input-group  mt-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="name"
                        style={{ marginLeft: '1em', marginRight: '1em' }}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </div>


                <div className="input-group  mt-3">
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        style={{ marginLeft: '1em', marginRight: '1em' }}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <div className="input-group  mt-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile Number"
                        name="number"
                        style={{ marginLeft: '1em', marginRight: '1em' }}
                        onChange={(e) => setMobile(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group mb-3 mt-3">
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        style={{ marginLeft: '1em', marginRight: '1em' }}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="row">
                    {/* /.col */}
                    <div className="col-12 signin">
                        <button

                            class="btn btn-primary sign"
                            onClick={(e) => submit(e)}
                        >
                            SUBMIT
                        </button>
                    </div>
                </div>
                <div className="row mt-2">
                    <span>already have an account<br /><Link to="/login">login</Link></span>
                </div>

                {/* {isLoading ? <LinearProgress color="secondary" /> : null} */}
            </div>

            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
        </div>

    );
};
// const styles = css`
// .no-outline:hover{
// background-color:"grey"
// }
// input:hover{
//     backgroud-color:grey;
// }
// `;


export default Signup;