/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Button from "../GlobalComponents/Button";
import axios from "axios";
// import logo from "./logo.svg";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

async function displayRazorpay() {
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    const result = await axios.post("http://localhost:5000/payment/orders");

    if (!result) {
        alert("Server error. Are you online?");
        return;
    }

    const { amount, id: order_id, currency } = result.data;

    const options = {
        key: "rzp_test_hVPY0wMjjV6ums", // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "Soumya Corp.",
        description: "Test Transaction",
        // image: { logo },
        order_id: order_id,
        handler: async function (response) {
            const data = {
                orderCreationId: order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };

            const result = await axios.post(
                "localhost:5000/payment/success",
                data
            );

            alert(result.data.msg);
        },
        prefill: {
            name: "Soumya Dey",
            email: "SoumyaDey@example.com",
            contact: "9999999999",
        },
        notes: {
            address: "Soumya Dey Corporate Office",
        },
        theme: {
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}

const Output = ({ title, info, img }) => (
    <div css={styles} className="output">
        <img src={img} alt="class" />
        <h2>{title}</h2>
        <p>{info}</p>
        {/* <a
            // href="https://meet.google.com/wod-fkzn-zxx?hs=224"
            href="https://docs.google.com/forms/d/e/1FAIpQLSclu3n3mXHIULo8P1YNgwA0SSmInO7XzD9g39kJ4p0h20NB6g/viewform?embedded=true"
            className="App-link btn btn-primary"
            // onClick={displayRazorpay}
        >
            Register Now
        </a> */}
        
    </div>
);

const styles = css`
    text-align: left;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
    h2 {
        margin: 34px 0 0 0;
        font-size: 22px;
        font-weight: 600;
        line-height: 1;
    }
    p {
        margin: 20px 0;
    }
    .btn {
        padding: 14px 26px;
    }
    @media (max-width: 900px) {
        img {
            height: 260px;
        }
    }
`;

export default Output;
