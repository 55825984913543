/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Button from "../GlobalComponents/Button";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const LinksContainer = ({ hidden }) => {
    const history = useHistory();

    const logout = () => {
        console.log("hii");
        history.push("/login");
        toast("Logout succesufull.");
        window.localStorage.clear();
        console.clear();
    };

    const [id, setId] = useState(
        localStorage.getItem("id") != "null"
            ? Number(localStorage.getItem("id"))
            : false
    );
    console.log(id);

    const [isMember, setIsMember] = useState(
        localStorage.getItem("isMember") != "null"
            ? Number(localStorage.getItem("isMember"))
            : false
    );
    console.log(isMember);

    return (
        <>
            <div
                css={styles}
                className={(hidden ? "hidden" : "") + " linksContainer"}
            >
                <a
                    href="#home"
                    style={{ textDecoration: "none", color: "white" }}
                >
                    HOME
                </a>

                <div className="dropdown">
                    {" "}
                    <button
                        class="dropbtn"
                        style={{
                            backgroundColor: "rgba(0,0,0,0)",
                            border: "none",
                            color: "white",
                        }}
                    >
                        ABOUT
                    </button>
                    <div class="dropdown-content">
                        <a class="nav-link scrollto" href="#about">
                            Our Mission
                        </a>
                        {/* <Link to="#about">Our Mission</Link> */}
                        <a class="nav-link scrollto" href="#about">
                            Services
                        </a>
                        <a class="nav-link scrollto" href="#about">
                            Partners
                        </a>
                    </div>
                </div>
                <div className="dropdown">
                    <button
                        class="dropbtn"
                        style={{
                            backgroundColor: "rgba(0,0,0,0)",
                            border: "none",
                            color: "white",
                        }}
                    >
                        UPCOMING EVENTS
                    </button>

                    <div class="dropdown-content">
                        <a class="nav-link scrollto" href="#ourClasses">
                            VectoGen
                        </a>
                        
                        <a class="nav-link scrollto" href="#ourClasses">
                            VectoGames
                        </a>
                        <a class="nav-link scrollto" href="#ourClasses">
                            Seminars
                        </a>
                        <a class="nav-link scrollto" href="#ourClasses">
                            VectoGames registration
                        </a>
                    </div>
                </div>
                <a
                    class="nav-link scrollto"
                    href="#Newsletter"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                >
                    NEWSLETTER
                </a>

                {/* <a
                    href="/gallery"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                >
                    GALLERY
                </a> */}
                <Link to="/gallery" className="text-white" style={{ textDecoration: "none" }}>GALLERY</Link>

                <a
                    class="nav-link scrollto"
                    href="#contact"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                >
                    CONTACT
                </a>

                <div className="dropdown">
                    <button
                        class="dropbtn"
                        style={{
                            backgroundColor: "rgba(0,0,0,0)",
                            border: "none",
                            color: "white",
                        }}
                    >
                        ⋮
                    </button>
                    <span
                        className="hover text-white"
                        style={{ fontSize: "18px" }}
                    ></span>
                    <div class="dropdown-content">
                        {/* <a href="/login" className={id === 0 && isMember === 0 ? "LOGIN " : "d-none"}>
                            LOGIN
                        </a> */}
                        <Link to="/login" className={id === 0 && isMember === 0 ? "LOGIN " : "d-none"}>LOGIN</Link>
                        <Link to="/signup" className={id === 0 && isMember === 0 ? "SIGNUP " : "d-none"}>SIGN UP</Link>
                        {/* <a href="/signup" className={id === 0 && isMember === 0 ? "SIGNUP " : "d-none"}>
                            SIGN UP
                        </a> */}
                        {id === 0 ? (
                            // <a href="/signup">MEMBERSHIP</a>
                            <Link to="/signup">MEMBERSHIP</Link>
                        ) : (
                            // <a href="/member">MEMBERSHIP</a>
                            <Link to="/member">MEMBERSHIP</Link>
                        )}
                        {/* <a onClick={logout} style={{ cursor: "pointer" }}>
                            LOG OUT
                        </a> */}
                    </div>
                </div>
                {/* <Button text="SIGN UP" /> */}
            </div>
            {/* <button
        variant="outline-secondary"
        onClick={logout}
        size="sm"
        text=""
      >
        <i class="fas fa-sign-out-alt m-0"></i>
      </button> */}
        </>
    );
};

const styles = css`
    width: 100%;
    max-width: 620px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1000px) {
        max-width: 100%;
        padding: 0 30px 20px 30px;
        flex-direction: column;
        align-items: flex-start;
        :0.5 ;
        position: absolute;
        left: 0;
        top: 70px;
        background: rgba(35, 45, 57, 0.8);
        transition: top 1100ms ease-in-out, 1100ms ease-in-out;
        &.hidden {
            left: 0;
            top: -500px;
            opacity: 0.5;
        }
        .btn {
            width: 100%;
            text-align: center;
            padding: 16px;
        }
    }
`;

export default LinksContainer;
