/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import Output from "./Output";
import vectoGames from "./../../Image/vectoGames.jpg";
import VectoGen from "./../../Image/vectoGen.jpg";
import ClassThree1Bg from "../Image/classThree1.jpg";
import ClassFourBg from "../Image/classFour.jpg";
import seminar from "./../../Image/seminar.jpg";
import Iframe from "./iframe";

const Results = ({ training }) => (
    <div css={styles} className="results">
        {training === "FirstClass" && (
            <Output title="VectoGen" info="Coming Soon..." img={vectoGames} />
        )}

        {training === "SecondClass" && (
            <Output title="VectoGames" info="Coming Soon..." img={VectoGen} />
        )}
        {training === "ThirdClass" && <Output title="Seminars" img={seminar} />}
        {training === "FourthClass" && <Iframe title="Seminars" />}

        {/* 
    {training === "FourthClass" && (
      <Output
        title="Fourth Training Class"
        info="Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean ultrices elementum odio ac tempus. Etiam eleifend orci lectus, eget venenatis ipsum commodo et."
        img={ClassFourBg}
      />
    )} */}
    </div>
);

const styles = css`
    width: 100%;
    max-width: 60%;
    .test {
        width: 100%;
        height: 400px;
        background: red;
        &.two {
            background: blue;
        }
    }
    @media (max-width: 900px) {
        max-width: 590px;
        padding: 30px 0 0 0;
    }
    @media (min-width: 901px) and (max-width: 1226px) {
        max-width: 62%;
    }
`;

export default Results;
