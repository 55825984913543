/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useState } from "react";
import Icon from "../GlobalComponents/Icon";
import { Link } from "react-router-dom";

const Newsletter = ({ text }) => {
    return (
        <section css={styles} className="ourClasses" id="Newsletter">
            <h2>NEWSLETTER</h2>
            <div
                style={{
                    borderBottom: "2px solid #0081f8",
                    width: "20%",
                    margin: "auto",
                    marginBottom: "20px",
                }}
            />
            <p>
                If you don’t want to miss any updates on sports science,
                research, and college athletics, make sure to sign up for our
                monthly newsletters.
            </p>
            <p>Sign up below Newsletter section</p>
            <Link to="signup" style={{ textDecoration: "none" }}>
                {" "}
                <span
                    style={{
                        color: "white",
                        backgroundColor: "#0081f8",
                        padding: "15px",
                        borderRadius: "5px",
                    }}
                >
                    Sign Up !
                </span>{" "}
            </Link>
        </section>
    );
};

const styles = css`
    width: 100%;
    padding: 100px 0;
    text-align: center;
    h2 {
        color: #232d39;
        font-size: 26px;
        font-weight: 900;
        line-height: 1;
        span {
            color: #ba1a32;
        }
    }
    p {
        color: #7a7a7a;
        font-size: 15px;
        line-height: 1.7;
    }
    .container {
        display: flex;
        justify-content: space-between;
        padding: 80px 0 0 0;
    }
    @media (max-width: 900px) {
        .container {
            flex-direction: column;
            align-items: center;
        }
    }
    @media (max-width: 580px) {
        p {
            padding: 0 20px;
            br {
                display: none;
            }
        }
    }
    @media (min-width: 901px) and (max-width: 1226px) {
        .container {
            justify-content: space-between;
            max-width: 90%;
        }
    }
`;

export default Newsletter;
