/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import athelete from "./../../Image/athelete.jpg";

const Map = () => (
    <div css={styles} className="mapouter">
        <img src={athelete} />
    </div>
);

const styles = css`
    width: 100%;
    max-width: 50%;
    min-height: 60vh;
    iframe {
        width: 100%;
        height: 100%;
        display: block;
        border: none;
    }
    @media (max-width: 1200px) {
        max-width: 100%;
        height: 60vh;
    }
`;

export default Map;
