/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React from "react";
import DumbbellBg from "../Image/dumbbellOrange.png";
import Button from "../GlobalComponents/Button";

const Iframe = () => (
    
<iframe className="output" src="https://docs.google.com/forms/d/e/1FAIpQLSclu3n3mXHIULo8P1YNgwA0SSmInO7XzD9g39kJ4p0h20NB6g/viewform?embedded=true" width="640" height="850" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>);



export default Iframe;
