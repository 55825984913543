import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "./../global";
import { useHistory } from 'react-router-dom';
import MASTER_LOGO from "../../Image/MASTER_LOGO.png";


// import {AuthContext} from '../../Context/authContext';



const axios = require("axios");


const Login = () => {
    const history = useHistory();

    //  const [_name,_type,_id] = useContext(AuthContext);
    //  const [name, setName] = _name;
    //  const [type, setType] = _type;
    //  const [id, setId] = _id;


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const Authenticate = (_password, _id, _email, _member) => {
        if (_password == password) {
            // setId(_id);
            // setName(_name);
            // setType(_type);


            localStorage.setItem("id", _id);
            parseInt(localStorage.id);
            localStorage.setItem("emailId", _email);
            localStorage.setItem("isMember", _member);
            parseInt(localStorage.isMember);


            if (_member == 1) {
                toast("Login Succesfull");
                history.push("/");
            }
            else {
                toast("Login Failed");
                history.push("/login");
            }

        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        let url = BASE_URL;

        //production
        const query = `SELECT * from users where emailId like '${email}' AND password like '${password}';`;
        console.log(query)
        //dev
        //const query = `SELECT * from users where name like 'admin' AND password like 'admin@123';`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                if (res.data.length > 0) {
                    toast("Login Succesfull");
                    Authenticate(res.data[0].password, res.data[0].id, res.data[0].emailId, res.data[0].isMember);
                    history.push("/")
                    
                } else {
                    toast("Incorrect email and password");
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("HTTP request error: ", err);
            });

    };



    return (

        <div className="wrapper background text-center" style={{ marginTop: '1em' }}>

            <div className="Heading">
                <img
                    src={MASTER_LOGO}
                    alt="logo"
                    className="img-fluid"
                    width={300}
                    height={200}
                />
            </div>
            <div
                className="pt-5 pb-5 pl-3 pr-3 w-25 login-temp"
                style={{ boxShadow: "0px 0px 30px 0px rgba(30, 23, 16, 0.2)", margin: 'auto' }}
            >
                <div className="text-center mb-3">

                    <h5 className="sub-head">LOGIN</h5>
                </div>


                <form
                    onSubmit={handleSubmit}
                    action="/"
                    method="post"
                    className="mt-5"
                >
                    <div className="input-group  mt-3">
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            style={{ marginLeft: '1em', marginRight: '1em' }}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>

                    <div className="input-group mb-3 mt-3">
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            placeholder="Password"
                            style={{ marginLeft: '1em', marginRight: '1em' }}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="row">
                        {/* /.col */}
                        <div className="col-12 signin">
                            <button

                                class="btn btn-primary sign"
                                onClick={(e) => handleSubmit(e)}
                            >
                                LOGIN
                            </button>
                        </div>

                        {/* /.col */}
                        {/* <p class="col-12 mb-1 ">
                            <a
                                href="forgot-password.html"
                                className="forgot mx-auto my-3"
                            >
                                I forgot my password
                            </a>
                        </p> */}
                    </div>
                    
                </form>

                {isLoading ? <LinearProgress className="mt-2" color="secondary" /> : null}
            </div>

            <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} />
        </div>
    );
};

export default Login;
