import React from "react";
import "./App.css";
// import Main from "./components/Main/Main";
// import GymProgram from "./components/GymProgram/GymProgram";
// import Member from "./components/Member/Member";
// import Classes from "./components/OurClasses/Classes";
// import Schedule from "./components/Schedule/Schedule";
// import Trainers from "./components/Trainers/Trainers";
// import Contact from './components/Contact/Contact';
import { BrowserRouter as Router, Route, Link, HashRouter, BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home/Home";
import Memberfun from "./components/Main/memberfunction";
import Signup from "./components/signup/signup";
import Login from "./components/login/login";
import Gallery from "./components/gallery/gallery";

const App = () => (
    <div className="App">
        {/* <Main />
    <GymProgram />
    <Member />
    <Classes />
    <Schedule />
    <Trainers />
    <Contact /> */}
        <BrowserRouter>
            <switch>
                <Route path="/" component={Home} exact />
                    <Route path="/member" component={Memberfun} />
                <Route path="/abcd" />
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/gallery" component={Gallery} />

                {/* <Route path='/Member1' component={Abc} /> */}
                {/* <Route path='/Member1' component={Abc} /> */}
            </switch>
        </BrowserRouter>
      
    </div>
);

export default App;
